import request from '@/utils/request'
let development = process.env.NODE_ENV == 'development' ;
let hostUrl = null;
if(development) {
    // document.domain = 'ffttt.com'; // 开发环境
    // document.domain = 'localhost'; // 开发环境
    hostUrl = 'http://localhost';
} else {
    hostUrl ="https://test-meta.frontopbd.com"; // 生产环境
}

//获取用户登陆token
// export function fetchUserLoginToken(params) {
//     return request({
//         url: '/inside/app/user/login',
//         method: 'post',
//         data: params
//     })
// }

//新获取用户登录token
export function fetchUserLoginToken(params) {
    return request({
        url: '/inside/app/user/login/V1',
        method: 'post',
        data: params
    })
}
//新获取短信验证码
export function fetchUserLoginSmsCode(params) {
    return request({
        url: hostUrl +  `/admin/inner/sms/captcha/send`,
        method: 'post',
        data: params
    })
}


//获取fantuo用户登录token
export async function FTLoginToken(params) {
    return request({
        url:  hostUrl + '/auth/mobile/token/sms',
        method: 'post',
        headers: {
            Authorization: "Basic YXBwOmFwcA==",
            'Content-Type': 'application/x-www-form-urlencoded',
            // referer: 'https://8.134.143.183/',
            // host: '10.6.7.156:9999/'
        },
        data: params
    })
}