const SWINDLER_GO_DIE = [
    '时刻绷紧防范之弦，谨防新型电信诈骗。',
    '号码陌⽣勿轻接，虚拟电话设陷阱。',
    '飞来⼤奖莫惊喜，让您掏钱洞⽆底。',
    '不存贪婪⼼，诈骗难得逞。',
    '提⾼防骗意识，增强防范能⼒，构筑电信诈骗“防⽕墙。',
    '骗⼈之⼼不可有，防骗之⼼不可⽆。',
    '⽹上汇款需警惕，电话核实莫⼤意。',
    '执法办案有规范，怎会汇款到个⼈。',
    '不明电话及时挂，可疑短信不要回。',
    '⽹络购物便利多，⽀付流程要仔细。',
    '投资理财和股票，多是骗⼦设的套。',
    '不信陌⽣短信，拒接陌⽣来电，让骗⼦⽆从下⼿。',
    '⼀不贪⼆不占，诈骗再诡玩不转。',
    '遇到恐吓要淡定，说你违法莫慌张，⼀旦难分真与假，警方电话110。',
    '陌⽣来电要提防，多⽅确认防上当。',
    '致富⼗年功，诈骗⼀场空。',
    '积极加强⾃我防范意识，共同提⾼识骗防骗能⼒。',
    '防范⽹络的骗术，不贪便宜要记住。',
    ' 和谐⽹络你我共享，电信诈骗⼤家共防。',
    '真假⽹店难分辨，购物不慎就被骗。',
    '个⼈信息顶重要，密码账号保管好。',
    '飞来⼤奖莫惊喜，让你掏钱洞⽆底。',
    '安全账户⼦虚有，⼤额汇款要三思。',
    '异地刷卡消费现，不要着急忙给钱。',
    '电话通知接传票，实为骗钱设圈套。',
    '刷卡消费莫离眼，防⽌盗刷盯着点。',
    '⼼中⽆贪念，骗局远⾝边。',
    '转账汇款须谨慎，万元以上到柜⾯。',
    '陌⽣电话勿轻信，对⽅⾝份要核清。',
    '电信诈骗不难防，不给不要不上当。',
    '陌⽣信息不要理，以防害⼈⼜害⼰。',
]

const EASEIM_HINT =
  '【安全提示】本应用仅用于环信产品功能开发测试，请勿用于非法用途。任何涉及转账、汇款、裸聊、网恋、网购退款、投资理财等统统都是诈骗，请勿相信！'
export default { SWINDLER_GO_DIE, EASEIM_HINT }
