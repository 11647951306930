<script setup>
import { ref } from 'vue'
import { EaseChatClient } from '@/IM/initwebsdk'
import CustomImConfig from '@/views/Login/components/CustomImConfig'
import LoginInput from './components/LoginInput'
import RegisterInput from './components/RegisterInput'
import ResetPassword from './components/ResetPassword'

//login图
// const logo = require('@/assets/images/loginIcon.png')
// 登陆注册所用
const showComponent = ref(0)
const componType = {
    0: LoginInput,
    1: RegisterInput,
    2: ResetPassword
}
const changeToLogin = () => {
    showComponent.value = 0
}
const toEasemob = () => {
    const linkUrl = 'https://www.easemob.com/?utm_source=baidu-ppwx'
    window.open(linkUrl, 'Easemob')
}

//服务配置
const customImConfig = ref(null)
// const showCustomImConfigModal = () => {
//   customImConfig.value.centerDialogVisible = true
// }

//SDK-Version
const IM_SDK_VERSION = EaseChatClient.version
</script>
<template>
    <el-container class="app_container-login">
        <el-main class="login_box">
            <div
                class="login_box_card out-drawer animate__animated animate__slideInLeft"
            >
                <div class="logo" @click="toEasemob"></div>
                <!-- <component :is="componType[showComponent]" @changeToLogin="changeToLogin"></component> -->
                <component
                    :is="componType[0]"
                    @changeToLogin="changeToLogin"
                ></component>
                <el-col v-show="showComponent !== 2">
                    <div class="function_button_extra">
                        <!-- <el-link class="reset_password" @click="showComponent = 2">重置密码</el-link> -->
                        <!-- <el-link class="custom_config" @click="showCustomImConfigModal">服务器配置</el-link> -->
                        <!-- <p class="login_text">
                <span class="login_text_isuserid" v-show="showComponent === 0">没有账号？</span>
                <span class="login_text_isuserid" v-show="showComponent === 1">已有账号？</span>
                <span class="login_text_tologin" v-show="showComponent === 0" @click="showComponent = 1">注册</span>
                <span class="login_text_tologin" v-show="showComponent === 1" @click="showComponent = 0">登录</span>
              </p> -->
                    </div>
                </el-col>
            </div>
            <div class="fontuo-logo"></div>
        </el-main>
        <el-footer>
            <!-- <div class="copyright">Copyright © easemob Web IM SDK版本号：{{ IM_SDK_VERSION ? IM_SDK_VERSION : '4.x' }}</div> -->
        </el-footer>
        <CustomImConfig ref="customImConfig" />

        <!-- <currentState /> -->
    </el-container>
</template>

<style lang="scss" >
.app_container-login {
    width: 100%;
    height: 100%;
    background: url('@/assets/images/web-demo-base.png');
    background-size: cover;

    .el-form-item {
        margin-bottom: 4px;
    }
    .el-input-group__append button.el-button {
        font-size: 14px;
    }
    .login_box {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        .el-input__inner {
            -webkit-text-fill-color: rgb(19 20 20) !important;
            font-family: 'Alibaba PuHuiTi-Regular';
        }
        .el-input__inner::placeholder {
            -webkit-text-fill-color: rgb(19 20 20 / 20%) !important;
        }
        .el-input__wrapper {
            background-color: #fff !important;
        }
        .login_box_card {
            width: 580px;

            border-radius: 5px;
            backdrop-filter: blur(19px);
            background: rgba(19, 20, 20, 0.18);
            height: 680px;
            position: absolute;
            right: 134px;
            top: 0;
            bottom: 0;
            border-radius: 28px;
            margin: auto;
            .logo {
                width: 232px;
                height: 80px;
                margin-bottom: 36.97px;
                transition: all 0.3s;
                background-image: url('@/assets/images/loginIcon.png');
                background-size: 100% 100%;
                margin: 140px auto 50px;
            }

            .login_input_style {
                margin: 10px 0;
                height: 56px;
                font-size: 17px;
                border-radius: 28px;
                overflow: hidden;
            }

            .function_button_box {
                margin-top: 10px;
                button {
                    margin: 10px 0;
                    width: 414px;
                    height: 54px;
                    background: linear-gradient(
                        90deg,
                        #04aef0 0%,
                        #5a5dd0 100%
                    );
                    border: none;
                    font-weight: 300;
                    font-size: 17px;
                    border-radius: 57px;

                    &:active {
                        background: linear-gradient(
                            90deg,
                            #0b83b2 0%,
                            #363df4 100%
                        );
                    }
                }
            }

            .function_button_extra {
                width: 400px;
                // height: 35px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 10px;
            }

            .custom_config,
            .reset_password {
                margin-left: 20px;
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 17px;
                text-align: right;
                color: #fff;
                cursor: pointer;
            }

            .login_text {
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 17px;
                text-align: right;

                .login_text_isuserid {
                    display: inline-block;
                    // width: 100px;
                    color: #f9f9f9;
                }

                .login_text_tologin {
                    margin-right: 20px;
                    width: 80px;
                    color: #05b5f1;
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        .el-form-item__content {
            justify-content: center;
        }
    }
    .fontuo-logo {
        width: 561px;
        height: 172px;
        position: absolute;
        top: 81px;
        left: 144px;
        background-image: url('@/assets/images/Group396.png');
        background-size: 100% 100%;
        // display: none;
    }
    .el-input-group__append {
        box-shadow: 0 0 0 0 transparent;
        width: 100px;
    }
    .copyright {
        width: 100%;
        height: 30px;
        line-height: 30px;
        text-align: center;
        mix-blend-mode: normal;
        opacity: 0.4;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: #ffffff;
    }
}

/*定义竖屏 css*/
@media screen and (orientation: portrait) {
    .fontuo-logo {
        display: none;
    }
    .app_container-login .login_box .login_box_card {
        width: 100%;
        right: 0px;
        padding: 10px;
    }
}
@media screen and (max-width: 1200px) {
    .fontuo-logo {
        display: none;
    }
}
// /*定义横屏 css*/
// @media screen and (orientation:landscape) {
// 	.fontuo-logo  { display: none;}
// }
</style>
